@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&family=Montserrat&family=Open+Sans:wght@300;400;600&family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&family=Open+Sans:wght@600&family=Roboto:wght@100;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* .tooltiptext {
    display: none;
}

.open-acc:hover .tooltiptext {
    display: block;
} */

